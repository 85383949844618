import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { FaBars } from 'react-icons/fa'
import { IconContext } from "react-icons"

import "../styles/style.scss"
import "../styles/style.css"

const Header = ( ) => {

  const HeaderSelector=[
    {
      name:"Home",
      link:"/",
    },
    {
      name:"About",
      link:"/#about",
    },
    {
      name:"Acts and Rules",
      link:"/#acts",
    },
    {
      name:"Contact Us",
      link:"/#contact",
    },
    {
      name:"Services",
      link:"/divorce-advocate-lawyer-ernakulam-family-court-mutual/",
    }
  ]

  const [mobl,mobval] = useState("navbar-list")

  return(
    <nav className= "navbar navbar-top" >
          {/* <Link className="logo" to="/"></Link> */}
          <div className= {mobl}>
                {HeaderSelector.map((contents) => (
                  <Link onClick={() => {
                    mobval((mobl === "navbar-list responive") ? "navbar-list" : "navbar-list responive" )
                  }} to={contents.link}>{ contents.name }</Link>
                ))}
          </div>  
            <IconContext.Provider value={{ className: "react-icons" }}>
              <div className="icon">
                <div  onClick= {() => {
                  mobval((mobl === "navbar-list") ? "navbar-list responive" : "navbar-list")
                  }}>
                  <FaBars />
                </div>
              </div>
            </IconContext.Provider>
      </nav>
  )
}



Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
